@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Colors */
  :root {
    --color-cream: 247 246 242;
    --color-grey: 239 237 231;
    --color-blood: 247 76 37;
    --color-half-blood: 247 136 109;
    --color-text-primary: 247 76 37;
    --color-text-secondary: 247 136 109;
    --color-error: 255 0 0; /* TODO: replace with actual color */
    --color-loader: 247 136 109;
    --color-overlay: 0 0 0;
    --color-advertisement: 241 239 233;
    --color-cta-background: 255 255 255;
    --color-cta-btn-background: transparent;

    --page-background-color: rgb(var(--color-cream));
  }

  :root[data-prefers-color-scheme-variant='secondary'] {
    --color-cream: 251 251 251;
    --color-grey: 239 237 231;
    --color-blood: 247 76 37;
    --color-half-blood: 247 136 109;
    --color-text-primary: 20 20 20;
    --color-text-secondary: 87 87 87; /* We stripped opacity */
    --color-error: 255 0 0; /* TODO: replace with actual color */
    --color-loader: 247 136 109;
    --color-overlay: 0 0 0;
    --color-advertisement: 241 239 233;
    --color-cta-background: 238 238 244;
    --color-cta-btn-background: var(--color-cream);
  }

  :root[data-prefers-color-scheme='dark'] {
    --color-cream: 17 17 17;
    --color-grey: 34 34 34;
    --color-blood: 238 238 238;
    --color-half-blood: 221 221 221;
    --color-text-primary: 238 238 238;
    --color-text-secondary: 221 221 221;
    --color-error: 255 0 0; /* TODO: replace with actual color */
    --color-loader: 221 221 221;
    --color-overlay: 0 0 0;
    --color-advertisement: 241 239 233;
  }

  /** Remove tap highlight */
  * {
    -webkit-tap-highlight-color: transparent;
  }

  /* Custom scrollbars */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgb(var(--color-half-blood)) transparent;
  }

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(var(--color-half-blood));
    border-radius: 4px;
  }

  /* Custom fonts */
  @font-face {
    font-family: "Suisse Int'l";
    src: url("./styles/fonts/Suisse Int'l/SuisseIntl-Regular-WebXL.woff2") format('woff2');
  }

  @font-face {
    font-family: "Suisse Int'l";
    font-style: italic;
    src: url("./styles/fonts/Suisse Int'l/SuisseIntl-RegularItalic-WebXL.woff2") format('woff2');
  }

  @font-face {
    font-family: "Suisse Int'l";
    font-weight: bold;
    src: url("./styles/fonts/Suisse Int'l/SuisseIntl-Bold-WebXL.woff2") format('woff2');
  }

  @font-face {
    font-family: "Suisse Int'l";
    font-weight: bold;
    font-style: italic;
    src: url("./styles/fonts/Suisse Int'l/SuisseIntl-BoldItalic-WebXL.woff2") format('woff2');
  }

  /** Fix delay before click event on touch devices */
  :root {
    touch-action: manipulation;
  }

  /** Restrict page minimal width */
  :root {
    --page-minimal-width: 0;
  }

  :root,
  body {
    min-width: var(--page-minimal-width);
  }

  /** Custom page overflow */
  :root {
    --page-overflow-y: auto;
  }

  body {
    overflow-y: var(--page-overflow-y);
  }

  /** Custom reset */

  /* Inherit fonts for inputs and buttons */

  input,
  button,
  textarea,
  select {
    font: inherit;
    letter-spacing: inherit;
  }

  /* Additional inputs reset */

  input,
  textarea {
    border-width: 0;
    border-radius: 0;
    background-color: inherit;
  }

  /* Prevent font boosting (known to work inconsistently and break things on iOS). */

  html {
    text-size-adjust: none;
  }

  /**
   * Stretch body to full viewport height
   *
   * @see https://dev.to/fenok/stretching-body-to-full-viewport-height-the-missing-way-2ghd
   */
  :root {
    min-height: 100%;

    display: flex;
    flex-direction: column;
  }

  body {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
  }

  #root {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
  }

  /* Typography */
  body {
    text-rendering: geometricPrecision;
    /* Otherwise text looks bolder in Safari  */
    -webkit-font-smoothing: antialiased;

    @apply bg-[var(--page-background-color)] font-body text-base text-text-primary;
  }

  /** Specials */
  body {
    /* Some specials set global styles */
    background: var(--page-background-color) !important;
    padding: 0 !important;
  }
}

@layer utilities {
  .overflow-anywhere {
    /* Recommended replacement for word-break: break-word */
    overflow-wrap: anywhere;
    /* overflow-wrap: anywhere is not supported in old Safari */
    word-break: break-word;
  }

  .default-outline {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }
}
