html {
  --flame-color-text-primary: #f74c25;
  --flame-color-text-secondary: #f7886d;
  --flame-color-background: #f7f6f2;
}

html[data-prefers-color-scheme-variant='secondary'] {
  --flame-color-text-primary: #141414ff;
  --flame-color-text-secondary: #575757ff;
  --flame-color-background: #fbfbfb;
}

html[data-prefers-color-scheme='dark'] {
  --flame-color-text-primary: #eeeeee;
  --flame-color-text-secondary: #dddddd;
  --flame-color-background: #111111;
}

/* Sensible styles for undecorated tweets */
blockquote.twitter-tweet {
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
}

div.twitter-tweet {
  /* These values are set inline */
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  iframe {
    /* Iframe width is updated on resize for no reason, which breaks iframe shrinking */
    width: auto !important;
  }
}

blockquote.tiktok-embed {
  margin: 0;
}

iframe.instagram-media {
  margin: 0 !important;
}
