.ProseMirror-view {
  color: var(--flame-color-text-primary);
  background-color: var(--flame-color-background);

  &.ProseMirror-view {
    word-break: normal;
    overflow-wrap: anywhere;
  }

  & > * {
    color: var(--flame-color-text-primary);
  }

  & > * {
    letter-spacing: -0.01em;
  }

  & > *:not(:last-child) {
    letter-spacing: -0.01em;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 400;
    font-size: (30rem/16);
    line-height: (36rem/16);
    letter-spacing: -0.02em;
  }

  h3 {
    font-weight: 400;
    font-size: 1.5625rem;
    line-height: 1.625rem;
    letter-spacing: -0.015em;
    font-feature-settings:
      'pnum' on,
      'onum' on,
      'salt' on;
  }

  a {
    color: var(--flame-color-text-primary);
    text-decoration: underline;

    @media (hover: hover) {
      &:hover {
        color: var(--flame-color-text-secondary);
      }
    }
  }

  blockquote {
    font-size: (30rem/16);
    line-height: (36rem/16);
    letter-spacing: -0.02em;
  }

  figure {
    display: flex;
    flex-direction: column;
    align-items: center;

    figcaption {
      margin-top: 20px;
      font-size: (16rem/16);
      line-height: (25rem/16);
      letter-spacing: 0;
      text-align: center;
      align-self: center;
    }
  }

  .picture-figure {
    picture {
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .video-iframe-figure {
    > div {
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden;
      height: 0;
      width: 100%;

      > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
